// Implementation take from: https://www.youtube.com/watch?v=ThCfN5WJ0cU&feature=emb_title

import React, {createContext, useReducer} from 'react';
import {generateProjectMap} from 'src/shared/utilities/entryHelper';

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
  projects: [],
  projectMap: {},
  entries: [],
  projectDetailId: null,
  projectDetailEntries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ENTRIES': {
      return {
        ...state,
        entries: action.entries || [],
      };
    }
    case 'ADD_ENTRY': {
      return {
        ...state,
        entries: [
          ...state.entries,
          action.entry,
        ],
      };
    }
    case 'UPDATE_ENTRY': {
      return {
        ...state,
        entries: state.entries.map((e) => (
          e.pk === action.entry.pk ? {...e, ...action.entry} : e
        )),
      };
    }
    case 'DELETE_ENTRY': {
      return {
        ...state,
        entries: state.entries.filter((e) => (e.pk !== action.pk)),
      };
    }
    case 'SET_PROJECTS': {
      return {
        ...state,
        projects: action.projects || [],
        projectMap: generateProjectMap(action.projects || []),
      };
    }
    case 'ADD_PROJECT': {
      const projects = [...state.projects, action.project];
      return {
        ...state,
        projects,
        projectMap: generateProjectMap(projects),
      };
    }
    case 'UPDATE_PROJECT': {
      const projects = state.projects.map((project) => (
        project.pk === action.project.pk ? action.project : project
      ));
      return {
        ...state,
        projects,
        projectMap: generateProjectMap(projects),
      };
    }
    case 'SET_PROJECT_DETAIL_ENTRIES': {
      return {
        ...state,
        projectDetailId: action.projectId,
        projectDetailEntries: action.entries || [],
      };
    }
    case 'CLEAR_PROJECT_DETAILS': {
      return {
        ...state,
        projectDetailId: null,
        projectDetailEntries: [],
      };
    }
    case 'ADD_PROJECT_DETAIL_ENTRY': {
      return {
        ...state,
        projectDetailEntries: [
          ...state.projectDetailEntries,
          action.entry,
        ],
      };
    }
    case 'UPDATE_PROJECT_DETAIL_ENTRY': {
      return {
        ...state,
        projectDetailEntries: state.projectDetailEntries.map((e) => (
          e.pk === action.entry.pk ? {...e, ...action.entry} : e
        )),
      };
    }
    case 'DELETE_PROJECT_DETAIL_ENTRY': {
      return {
        ...state,
        projectDetailEntries: state.projectDetailEntries.filter((e) => (
          e.pk !== action.pk
        )),
      };
    }
    default:
      throw new Error('Invalid action type in reducer.');
  }
};

const GlobalContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
