export const generateProjectMap = (projects) => {
  const projectMap = {};
  projects.forEach((project) => {
    projectMap[project.pk] = project;
  });
  return projectMap;
};


export const daysFromEntries = (entries, projects) => {
  const days = {};

  (entries || []).forEach((entry) => {
    days[entry.date] = days[entry.date] || {};
    days[entry.date]['entries'] = days[entry.date]['entries'] || {};
    days[entry.date]['entries'][entry.pk] = entry;
  });

  // Initialize today if there aren't any entries yet
  // const today = moment().format('YYYY-MM-DD');
  // if (!days[today]) {
  // days[today] = {
  // entries: {},
  // };
  // }

  return days;
};
