// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-support-index-js": () => import("../src/pages/account/support/index.js" /* webpackChunkName: "component---src-pages-account-support-index-js" */),
  "component---src-pages-checkout-index-js": () => import("../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-help-index-js": () => import("../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-index-js": () => import("../src/pages/journal/index.js" /* webpackChunkName: "component---src-pages-journal-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-projects-index-js": () => import("../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-signup-js": () => import("../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscription-index-js": () => import("../src/pages/subscription/index.js" /* webpackChunkName: "component---src-pages-subscription-index-js" */),
  "component---src-pages-subscription-new-index-js": () => import("../src/pages/subscription/new/index.js" /* webpackChunkName: "component---src-pages-subscription-new-index-js" */),
  "component---src-pages-todo-index-js": () => import("../src/pages/todo/index.js" /* webpackChunkName: "component---src-pages-todo-index-js" */),
  "component---src-pages-x-index-js": () => import("../src/pages/x/index.js" /* webpackChunkName: "component---src-pages-x-index-js" */)
}

