/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Not sure if these CSS imports are really necessary
import './src/shared/styles/themes.css';
import './src/shared/styles/global.css';

import React from 'react';
import GlobalContextProvider from 'src/context/globalContextProvider';

export const wrapRootElement = ({element}) => {
  return (
    <GlobalContextProvider>
      {element}
    </GlobalContextProvider>
  );
};
